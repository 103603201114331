<template>
  <div>
    <div class="header flexb">
      <img style="width:0.2rem;margin-left:0.2rem" @click="goback" src="../../assets/arrows.png" alt="">
      <div style="font-size:0.3rem;">岗位详情</div>
      <div style="font-size:0.3rem;" @click="search">筛选</div>
    </div>
    <!-- <navigation msg="岗位信息"> </navigation> -->
    <div class="postItem">
      <div class="flex">
        <div>
          <span class="span1">{{ detail.job_nickname }}</span>
          <!-- <span class="span2">{{detail.recruit == 1 ? '悬赏' : '猎头'}}</span> -->
          <span class="span3">{{ getStatus(detail.status) }}</span>
          <!-- <span class="span4" >已跟进</span> -->
        </div>
        <div class="salary">
          {{ detail.salary_min + "-" + detail.salary_max }}
        </div>
      </div>
      <div class="grey">
        {{ getYearsWork(detail.years_working) }} |
        {{ detail.education_background }} | 招聘人数{{
          detail.recruit_number
        }}人
      </div>
      <div class="flex grey1">
        <div>
          <img
            style="
              width: 0.25rem;
              height: 0.25rem;
              vertical-align: bottom;
              margin-right: 0.1rem;
            "
            src="../../assets/time.png"
            alt=""
          />
          <span>{{ detail.add_time }}</span>
        </div>
        <div>有效期 {{ effectivityTime(detail.expire) }}</div>
      </div>
      <div class="grey1 flex" style="margin-top: 0.25rem">
        <div class="">{{ detail.job }}</div>
        <div>岗位投递数 {{ detail.feed_number }}人</div>
      </div>
    </div>
    <van-tabs swipe-threshold="3" @click="change">
      <van-tab title="未委托"></van-tab>
      <van-tab title="已委托"></van-tab>
      <van-tab title="已完成"></van-tab>
      <van-tab title="未通过"></van-tab>
    </van-tabs>
    <!-- 行业大咖弹框-->
    <van-popup v-model="pop1" round :style="{ width: '70%' }">
      <div class="popcontainer">
        <img
          style="width: 100%"
          v-if="row.avatar_url == null"
          src="../../assets/1660287920565.png"
          alt=""
        />
        <img style="width: 100%" v-else :src="row.avatar_url" alt="" />
        <div class="popName">{{ row.referrer_nickname }}</div>
        <div style="text-align: center">
          {{ row.user_info ? row.user_info : "暂无内容" }}
        </div>
      </div>
    </van-popup>
    <div v-if="list.length > 0">
      <div
        class="box"
        @click.stop="goDetail(item.base_id)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="txt1">
          <span>委托时间：{{ item.entrust_time }}</span>
          <span class="read" v-if="item.is_view == 2">已读</span>
          <span class="noread" v-else>未读</span>
        </div>

        <div class="person_personInfo" style="padding-bottom: 0.25rem">
          <div class="person_personInfo">
            <!-- <img class="person_avatar" src="../../assets/user_1.png" alt=""> -->
            <img
              class="person_avatar"
              v-if="item.gender == '1'"
              src="../../assets/user_1.png"
              alt=""
            />
            <img
              class="person_avatar"
              v-else-if="item.gender == '2'"
              src="../../assets/user_2.png"
              alt=""
            />
            <img
              class="person_avatar"
              v-else
              src="../../assets/nosexAvatar.jpg"
              alt=""
            />
            <div>
              <div class="person_username">
                {{ item.name }}
                <span class="nopush" v-if="item.is_entrust == 0">未委托</span>
                <span class="nopush" v-if="item.is_entrust == 1">已委托</span>
                <span class="nopush" v-if="item.is_entrust == 2">未通过</span>

              </div>
              <div class="person_userInfo">
                性别：<span v-if="item.gender == 1">男</span
                ><span v-else-if="item.gender == 2">女</span>
                <span v-else>保密</span> | 年龄：{{ item.age || "保密" }}
                <span v-if="item.age">岁</span>
              </div>
              <div class="grey1" style="margin-top: 0.15rem">
                介绍人:{{ item.referrer_nickname }}
                <span @click.stop="dk(item)" class="dk"
                  >行业大咖倾情介绍 ></span
                >
              </div>
            </div>
          </div>
          <div style="color: #ff7626">
            <div class="username">{{ item.resume_integrity }}%</div>
            <div class="userInfo" style="color: #ff7626; text-align: center">
              适配度
            </div>
          </div>
        </div>

        <div class="iconlist">
          <div style="text-align: center" @click.stop="goReport(item.base_id)">
            <div class="item1">
              <img
                style="width: 0.6rem; object-fit: cover"
                src="../../assets/icon_1@2x.png"
                alt=""
              />
            </div>
            <span class="grey1" style="line-height: 0.6rem"
              >能力及适配度报告</span
            >
          </div>
          <div style="text-align: center" @click="kf">
            <div class="item1">
              <img
                style="width: 0.6rem; object-fit: cover"
                src="../../assets/icon_2@2x.png"
                alt=""
              />
            </div>
            <span class="grey1" style="line-height: 0.6rem">查看背调</span>
          </div>
          <div
            style="text-align: center"
            @click.stop="viewResume(item.base_id)"
          >
            <div class="item1">
              <img
                style="width: 0.6rem; object-fit: cover"
                src="../../assets/icon_3@2x.png"
                alt=""
              />
            </div>
            <span class="grey1" style="line-height: 0.6rem">查看简历</span>
          </div>
        </div>
        <div v-if="item.isShow">
          <div class="txt2">候选人掌握技能：</div>
          <div :class="item.isShowSkill ? 'abilityList' : 'abilityListNormal'">
            <div
              class="abilityItem"
              v-for="(item1, index) in item.assessment.skills_tags"
              :key="index"
            >
              {{ item1.tag_name }}
            </div>
          </div>
          <!-- <div class="viewMore" @click.stop="viewMoreSkill(item)">
                {{item.isShowTxt}} 
              </div> -->
        </div>

        <div class="flex isShow">
          <div
            @click.stop="progressdetail(item)"
            style="color: #ff7626; font-weight: 600; font-size: 0.25rem"
          >
          跟进候选人
            <!-- {{ item.is_entrust == 1 ? "已跟进 | 查看进度" : "未跟进" }} -->
          </div>
          <div @click.stop="viewMoreSkill(item)">{{ item.isShowTxt }}</div>
        </div>
      </div>
    </div>
    <div
      style="text-align: center; margin-top: 1rem; font-size: 0.25rem"
      v-else
    >
      <img
        style="width: 2.5rem; margin-top: 2rem"
        src="../../assets/no_msg.4a8aaa9.png"
        alt=""
      />
      <div style="margin-top: 0.1rem; color: #606266">--- 暂无数据 ---</div>
    </div>
    <van-popup v-model="show" round>
      <div class="boxx">
        <div class="title">{{ name }}进度详情</div>
        <div class="scrollbar">
          <div class="item" v-for="(item, index) in flowList" :key="index">
            <div class="box1">
              <div class="left">{{ item.flow_time }}</div>
              <div class="right">
                <span v-if="item.see_rounds != 0"
                  >面试轮次 {{ item.num }}:
                </span>
                <span>{{ flowStatus(item.flow_status) }}</span>
              </div>
            </div>
            <template v-if="item.who_see != 2">
              <div class="PTB_2" v-if="item.flow_term">
                反馈详细：{{ item.flow_term }}
              </div>
              <div v-if="item.flow_note">备注：{{ item.flow_note }}</div>
            </template>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState,mapActions} from "vuex"
export default {
  data() {
    return {
      flowList: [],
      show: false,
      detail: {},
      list: [],
      pop1: false,
      row: {},
      name: "",
    };
  },
  mounted() {
    this.getDetail();
    this.getList(0,"");
  },
  methods: {
    search(){
      this.$router.push("/personDetail/searchResume")
    },
    goback(){
      this.$router.go(-1)
    },
    flowStatus(status) {
      let str;
      switch (status) {
        case 1:
          str = "已投递";
          break;
        case 2:
          str = "已委托";
          break;
        case 3:
          str = "已接单";
          break;
        case 4:
          str = "已驳回 ";
          break;
        case 5:
          str = "接单已确定";
          break;
        case 6:
          str = "已撮合";
          break;
        case 7:
          str = "面试反馈";
          break;
        case 8:
          str = "面试反馈";
          break;
        case 9:
          str = "已入职";
          break;
        case 10:
          str = "未入职";
          break;
        case 11:
          str = "已过保";
          break;
        case 12:
          str = "未过保";
          break;
        case 13:
          str = "结算完成";
          break;
      }
      return str;
    },
    progressdetail(item) {
      if (item.is_entrust == 1) {
        this.name = item.name;
        // this.flowList = ite
        let param = {
          reqType: "record",
          mim_id: localStorage.getItem("userId"),
          resume_id: item.base_id,
        };
        this.$http.post("/firm/v1/record/sendSchedule", param).then((res) => {
          res = JSON.parse(res.data);
          if (res.code == 0) {
            this.flowList = res.data;
            this.show = true;
            let arr = [];
            let num = 0;
            this.flowList.forEach((item) => {
              if (item.see_rounds != 0) {
                num += 1;
                item.num = num;
              }
            });
          }
        });
      }
    },
    dk(item) {
      this.row = item;
      this.pop1 = true;
    },
    kf() {
      this.$toast("开发中");
    },
    viewMoreSkill(item1) {
      let a = this.list.map((item) => {
        if (item1.base_id == item.base_id) {
          item.isShowSkill = !item.isShowSkill;
          if (item.isShowSkill == true) {
            item.isShowTxt = "收起";
          } else {
            item.isShowTxt = "展开查看详情";
          }
        }
        return item;
      });
      this.list = a;
    },
    change(e) {
      if (e == 0) {
        this.getList(0,"");
      } else if (e == 1) {
        this.getList(1,"");
      } else if(e == 2){
        this.getList("",130);
      } else {
        this.getList(2,"");
      }
    },
    goDetail(id) {
      this.$router.push({
        path: "/personDetail",
        query: {
          id,
        },
      });
    },
    // handleIsShow(id,show){
    // let list =  this.list.map((item)=>{
    //   if(id == item.base_id) {
    //     item.txt = show == true ? "展开" : "收起"
    //     item.isShow = !item.isShow
    //   }
    //   return item
    // })
    // this.list = list
    // },
    getYearsWork(num) {
      let year;
      switch (Number(num)) {
        case 0:
          year = "不限";
          break;
        case 1:
          year = "1年以内";
          break;
        case 2:
          year = "1-3年";
          break;
        case 3:
          year = "3-5年";
          break;
        case 4:
          year = "5-10年";
          break;
        case 5:
          year = "10年以上";
          break;
      }
      return year;
    },
    // 有效期
    effectivityTime(type) {
      let str;
      switch (type) {
        case 1:
          str = "30天";
          break;
        case 2:
          str = "60天";
          break;
      }
      return str;
    },
    getStatus(status) {
      let str;
      switch (status) {
        case 0:
          str = "待发布";
          break;
        case 1:
          str = "待支付";
          break;
        case 2:
          str = "招聘中";
          break;
        case 3:
          str = "关闭";
          break;
      }
      return str;
    },
    goReport(id) {
      // this.$router.push("/personReport")
      this.$router.push({
        path: "/personReport",
        query: {
          id,
        },
      });
    },
    viewResume(id) {
      this.$router.push({
        path: "/resumeDetails",
        query: {
          id,
        },
      });
    },
    getList(is_entrust,code) {
      this.$http
        .post("/firm/v1/record/postResumeList", {
          reqType: "record", //接口请求类型
          sub_id: this.$route.query.id, //岗位id
          is_entrust,
          status_code:code,
          degree: this.educVal ? this.educVal : "",
          gender: this.sexVal ? this.sexVal :"",
          page: 1,
        })
        .then((res) => {
          res = JSON.parse(res.data);
          res.data.data.map((item) => {
            item.assessment = JSON.parse(item.assessment);
            item.isShowSkill = false;
            item.isShowTxt = "展开查看详情";
            item.isShow = true;
            item.txt = "收起";
            return;
          });
          res.data.data.forEach((item) => {
            console.log(item.assessment);
          });
          this.list = res.data.data;
        });
    },
    getDetail() {
      this.$http
        .post("/firm/v1/record/postDetails", {
          reqType: "record",
          sub_id: this.$route.query.id, //岗位id
          is_selected: "", //是否被选中1 -是 0-否  null为全部
        })
        .then((res) => {
          res = JSON.parse(res.data);
          this.detail = res.data;
          console.log(res);
        });
    },
  },
  computed: {
    ...mapState({
      educVal:state => state.educVal,
      sexVal:state => state.sexVal,
    })
  },
};
</script>
<style scoped lang='scss'>
.nopush{
  width: 10%;
  background: #FFFFFF;
  border-radius: 0.05rem;
  border: 0.01rem solid #EBEBEB;
  color:#999999;
  font-size:0.2rem;
  padding:0.08rem 0.15rem;
}
.read{
  width: 10%;
  padding:0.08rem 0.18rem;
  background: #0379FB;
  border-radius: 0.2rem;
  color:#FFF;
}
.noread{
  width: 10%;
  padding:0.08rem 0.18rem;
  background: #EBEBEB;
  color:#7583A2;
  border-radius: 0.2rem;
  color:#FFF;
}
.boxx {
  position: relative;
  width: 5rem;
  height: 6rem;
  overflow: hidden;
  .title {
    text-align: center;
    font-size: 0.3rem;
    margin: 0.3rem 0;
  }
  .scrollbar {
    font-size: 0.28rem;
    overflow: auto;
    height: calc(100% - 0.7rem);
    .item {
      margin-bottom: 0.2rem;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #ccc;
      .box1 {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.viewMore {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff7626;
  text-align: center;
  line-height: 0.8rem;
}
.postItem {
  margin: 0;
}
.box {
  padding: 0 0.3rem;
  background: #fff;
  margin: 0.3rem 0.2rem;
}
.dk {
  padding: 0.05rem 0.1rem;
  background: linear-gradient(270deg, #FF8600 0%, #FF4E00 100%);;
  border-radius: 0.1rem;
  color: #fff;
}

.iconlist {
  margin-top: 0.2rem;
}
.abilityList {
  padding-bottom: 0.5rem;
}
.isShow {
  height: 1rem;
  line-height: 1rem;
  border-top: 0.01rem solid #ddd;
  margin-top: 0.2rem;
}
.header {
  background: #fff;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #ddd;
}

.popName {
  line-height: 0.7rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  text-align: center;
}
.popcontainer {
  padding: 0.2rem;
  box-sizing: border-box;
}
</style>